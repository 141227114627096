import React, { useContext } from "react";

import { mxpPricingCalculatorOpen } from "../../utils/mixpanelEvents/mixpanelEvents";
import { calculatorOpen } from "../PricingCalculator/calculatorReducer/calculatorActions";
import TryItFreeButton from "../PricingCard/TryItFreeButton";

import PricingFeaturesSummary from "../PricingFeaturesSummary";
import PricingPlanFeatures from "../PricingPlanFeatures";
import PricingCardSlider from "../PricingCardSlider";
import { PricingHeroProviderContext } from "../../contexts/PricingHeroProvider.context";
import { usePricingCalculator } from "../../contexts/CalculatorContext";
import { IPricingV2 } from "@types";
import useWindowSize from "../../hooks/useWindowSize";

import styles from "./PricingCardContent.module.scss";

type PricingCardContentType = {
  btnColor: "red" | "white";
  cardMobAddOns: IPricingV2.PricingAddonRepeaterType;
  cardMobList: IPricingV2.PricingCardMobFeatureType;
  featuresSections: IPricingV2.PricingV2CardsSoloCard["featuresSections"];
  cardGroup?: IPricingV2.PricingGroupCardType;
  price: number | string;
  subcopy: string;
  title: string;
  locationName: string;
  isGroups?: boolean;
};

const PricingCardContent: React.FC<PricingCardContentType> = ({
  btnColor,
  cardMobAddOns,
  cardMobList,
  cardGroup,
  locationName,
  price,
  subcopy,
  title,
  isGroups,
}) => {
  const { totalPricePerClinicians } = useContext(PricingHeroProviderContext);
  const {
    dispatch,
    timeState: { setCalculatorTime },
  } = usePricingCalculator();
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth < 1024 ? true : false;

  const handleOpenCalculator = (e: React.MouseEvent<HTMLSpanElement>) => {
    const timeSpent = Math.floor(new Date().getTime() / 1000);
    setCalculatorTime(prevState => ({ ...prevState, timeSpent }));

    mxpPricingCalculatorOpen();

    dispatch(calculatorOpen(title));
  };

  const showPricingFeaturesSummary = cardMobList && cardMobAddOns && (
    <PricingFeaturesSummary
      className={styles.pricingFeatures}
      cardAddOns={cardMobAddOns}
      cardFeaturesList={cardMobList}
    />
  );

  const pricingPlanFeatures = isMobile && cardMobList && (
    <PricingPlanFeatures
      className={styles.pricingPlans}
      cardMobAddOns={cardMobAddOns}
      cardMobList={cardMobList}
    />
  );

  const cardPrice = isGroups ? (
    <div>
      <p className={styles.cardPrice} data-testid="pricing-card-price">
        <strong>{`$${totalPricePerClinicians}`}</strong>/mo
      </p>
    </div>
  ) : (
    <p data-testid="pricing-card-price" className={styles.cardPrice}>
      <strong>{`$${price}`}</strong>/mo
    </p>
  );

  const contentStyles = `${styles.cardContent} ${
    isGroups && styles.withSlider
  }`;

  const withGroups = isGroups && cardGroup;
  const sliderLarge = withGroups && (
    <PricingCardSlider
      className={`${styles.slider} hide-for-small`}
      microcopy={cardGroup.sliderCardMicrocopy}
      title={cardGroup.sliderCardTitle}
      tooltip={cardGroup.sliderCardTooltip}
    />
  );

  const sliderSmall = withGroups && (
    <PricingCardSlider
      className={`${styles.slider} hide-for-large`}
      subcopy={cardGroup.sliderCardSubcopy}
      tooltip={cardGroup.sliderCardTooltip}
    />
  );

  return (
    <div className={contentStyles}>
      {sliderLarge}

      <div className={`${styles.rightSide} ${styles.contentCard}`}>
        <div>
          <h2 data-testid="pricing-card-title" className={styles.cardTitle}>
            {title}
          </h2>
          <p data-testid="pricing-card-subcopy" className={styles.cardSubcopy}>
            {subcopy}
          </p>
          <p
            data-testid="pricing-card-starting-at"
            className={styles.cardStartingAt}
          >
            STARTING AT
          </p>

          {cardPrice}

          {sliderSmall}
          <TryItFreeButton
            backgroundColor={btnColor}
            locationName={locationName}
            pricingPlan={title}
          >
            Start for free
          </TryItFreeButton>
        </div>
        {showPricingFeaturesSummary}
        {pricingPlanFeatures}
        <span
          data-testid="pricing-card-customize-plan"
          className={styles.customizePlan}
          onClick={handleOpenCalculator}
        >
          Select plan options
        </span>
      </div>
    </div>
  );
};

export default PricingCardContent;
