import React from "react";

import { IPricingV2 } from "@types";
import AddonsList from "./AddonsList";
import FeaturesList from "./FeaturesList";

import styles from "./PricingFeaturesSummary.module.scss";

type PricingFeaturesSummaryType = {
  cardAddOns: IPricingV2.PricingAddonRepeaterType;
  cardFeaturesList: IPricingV2.PricingCardMobFeatureType;
  className?: string;
};

const PricingFeaturesSummary: React.FC<PricingFeaturesSummaryType> = ({
  cardAddOns: { addOnRepeater },
  cardFeaturesList: {
    pricingCardMobileSectionTitle,
    pricingCardMobileFeatureList,
  },
  className,
}) => {
  const addonsList = addOnRepeater.length > 0 && [...addOnRepeater].reverse();

  return (
    <div className={`${styles.pricingFeaturesSummary} ${className}`}>
      {pricingCardMobileFeatureList?.length > 0 && (
        <FeaturesList
          featuresList={pricingCardMobileFeatureList}
          sectionTitle={pricingCardMobileSectionTitle}
        />
      )}

      <AddonsList addonsList={addonsList} />
    </div>
  );
};

export default PricingFeaturesSummary;
