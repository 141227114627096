import React from "react";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { usePricingCalculator } from "../../contexts/CalculatorContext";
import { IPricingV2 } from "@types";
import { calculatorOpen } from "../../components/PricingCalculator/calculatorReducer/calculatorActions";
import { mxpPricingCalculatorOpen } from "../../utils/mixpanelEvents/mixpanelEvents";

import styles from "./PricingV2BannerContainer.module.scss";

const PricingV2BannerContainer: React.FC<{ banner: IPricingV2.Banner }> = ({
  banner,
}) => {
  const {
    dispatch,
    isSoloPage,
    timeState: { setCalculatorTime },
  } = usePricingCalculator();

  const handleClick = (e: MouseEvent) => {
    const timeSpent = Math.floor(new Date().getTime() / 1000);
    setCalculatorTime(prevState => ({ ...prevState, timeSpent }));

    mxpPricingCalculatorOpen();
    dispatch(calculatorOpen(isSoloPage ? "starter" : "plus"));
  };

  return (
    <section className={`full-width ${styles.sectionContainer}`}>
      <div className={styles.pricingBanner}>
        <div className={styles.bannerContent}>
          <h2>{banner.bannerTitle}</h2>
          <p>{banner.bannerSubcopy}</p>
          <PrimaryButton
            onClickHandler={handleClick}
            className="white"
            externalClassName={styles.bannerButton}
            buttonText={banner.bannerLinkText}
          />
        </div>
        <img
          src={banner.bannerImage.localFile.publicURL}
          alt={banner.bannerImage.altText}
        />
      </div>
    </section>
  );
};

export default PricingV2BannerContainer;
