import React from "react";

import { IPricingV2 } from "@types";
import PricingFeatureItemsList from "../PricingFeatureItemsList/PricingFeatureItemsList";

import styles from "./PricingPlanFeatures.module.scss";

interface PricingPlanFfeaturesProps {
  className?: string;
  cardMobAddOns: IPricingV2.PricingAddonRepeaterType;
  cardMobList: IPricingV2.PricingCardMobFeatureType;
  cardType?: CARD_TYPE;
}
export enum CARD_TYPE {
  STARTER = "starterCard",
  ESSENTIAL = "essentialCard",
  PLUS = "plusCard",
}
const PricingPlanFeatures: React.FC<PricingPlanFfeaturesProps> = ({
  className = "",
  cardMobAddOns,
  cardMobList,
  cardType = CARD_TYPE.STARTER,
}) => {
  return (
    <div
      data-testid="div:PricingAccordion"
      className={`text-alignment ${styles[cardType]} ${className}`}
    >
      <div
        data-testid="div:active:PricingAccordion"
        className={styles.pricingPanel}
      >
        {cardMobAddOns && (
          <div className={styles.pricingOffers}>
            <PricingFeatureItemsList
              className={styles.featuresCardItem}
              featureItems={
                cardMobAddOns.addOnRepeater as IPricingV2.PricingAddonRepeaterType["addOnRepeater"]
              }
            />
          </div>
        )}
        {cardMobList && (
          <div id="plan-features-mob-list">
            <p className={styles.featureSectionTitle}>
              {cardMobList?.pricingCardMobileSectionTitle}
            </p>

            <PricingFeatureItemsList
              className={styles.featuresNormalCardItem}
              featureItems={cardMobList?.pricingCardMobileFeatureList}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default PricingPlanFeatures;
