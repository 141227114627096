import React, { useContext, useRef } from "react";

import { fireMxpEvent } from "../../utils/mixpanelEvents/mixpanelEvents";
import { PricingHeroProviderContext } from "../../contexts/PricingHeroProvider.context";
import useWindowSize from "../../hooks/useWindowSize";

import Tooltip from "../Tooltip/Tooltip";

import styles from "./PricingCardRange.module.scss";

type PricingCardRangeType = {
  tooltipCopy: string;
};

const PricingCardRange: React.FC<PricingCardRangeType> = ({ tooltipCopy }) => {
  const { clinicians, setClinicians, setTotalPricePerClinicians } = useContext(
    PricingHeroProviderContext
  );
  const { width } = useWindowSize();
  const isMdUpDevice = width >= 768;
  const exceededClinicians =
    isMdUpDevice && clinicians > 5 ? clinicians - 5 : clinicians - 1;
  const getTotal = (val: number) => 99 + (val - 1) * 59;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { valueAsNumber } = e.target;
    setClinicians(valueAsNumber);
    setTotalPricePerClinicians(() => {
      const total = getTotal(valueAsNumber);
      return total;
    });
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLInputElement>) => {
    if (e.type === "mouseup") {
      fireMxpEvent("user: pricing slider interaction", {
        sp_slider_value: clinicians,
        sp_slider_price: getTotal(clinicians),
      });
      window.dataLayer.push({
        event: "slider_interaction",
        slider_value: clinicians,
        slider_price: getTotal(clinicians),
        slide_name: "pricing slider",
      });
    }
  };

  const progressPercentage = `${(clinicians - 2) * 12}%`;

  const moreThan10 = clinicians >= 10 && (
    <div className={styles.moreThenTen}>
      <Tooltip
        className={styles.tooltipContainer}
        contentClassName={styles.tooltipContent}
        content={tooltipCopy}
      >
        <p className={styles.tooltip}>More than 10?</p>
      </Tooltip>
    </div>
  );

  const cliniciansCountStyle = (count: number) =>
    clinicians > count ? styles.active : undefined;

  const cliniciansImgs = new Array(5).fill(null).map((_, idx) => {
    const current = idx + 1;

    return (
      <li
        key={`img-${current}`}
        data-testid="pricing-clinicians-image"
        className={`${styles.cliniciansImage} ${cliniciansCountStyle(current)}`}
      >
        {current === 5 && (
          <div
            data-testid="pricing-clinicians-image-count"
            className={styles.countOverlay}
          >
            +{exceededClinicians}
          </div>
        )}
      </li>
    );
  });

  return (
    <div className={styles.clinicians}>
      <div>
        <ol className={styles.cliniciansImages}>
          <li
            data-testid="pricing-clinicians-image"
            className={`${styles.cliniciansImage} ${styles.active}`}
          ></li>
          {cliniciansImgs}
        </ol>

        <div className={styles.rangeSliderWrapper}>
          <div className={styles.rangeSliderContainer}>
            <input
              data-testid="pricing-input-range"
              onInput={handleChange}
              className={styles.priceSlider}
              type="range"
              min="2"
              max="10"
              step="1"
              value={clinicians}
              onChange={handleChange}
              onMouseUp={handleMouseUp}
            />
            <div
              className={styles.progressBar}
              style={{ width: progressPercentage }}
            ></div>
          </div>
        </div>
      </div>

      <div className={styles.slideMore}>
        <p className={styles.copy}>
          <span>{clinicians}</span> clinicians
        </p>
        {moreThan10}
      </div>
    </div>
  );
};

export default PricingCardRange;
