import * as React from "react";
import { PageProps, graphql, HeadProps } from "gatsby";

import MainLayout from "../../layouts/main";
import PricingV2TableSoloContainer from "../../containers/PricingV2TableSoloContainer/PricingV2TableSoloContainer";
import PricingV2BannerContainer from "../../containers/PricingV2BannerContainer/PricingV2BannerContainer";
import PricingV2SliderContainer from "../../containers/PricingV2SliderContainer/PricingV2SliderContainer";
import PricingV2SwitchingPanelContainer from "../../containers/PricingV2SwitchingPanelContainer/PricingV2SwitchingPanelContainer";
import PricingV2FAQsContainer from "../../containers/PricingV2FAQsContainer/PricingV2FAQsContainer";
import PricingV2TableGroupContainer from "../../containers/PricingV2TableGroupContainer/PricingV2TableGroupContainer";
import CtaBannerSmall from "../../components/Banners/CtaBannerSmall/CtaBannerSmall";
import PricingCalculator from "../../components/PricingCalculator/PricingCalculator";
import { CalculatorProvider } from "../../contexts/CalculatorContext";
import { IPricingV2, ContentFaqsPType, CtaBannerType } from "@types";
import { YoastSEOType } from "../../assets/Types";
import SEO from "gatsby-plugin-wpgraphql-seo";
import { PricingHeroProvider } from "../../contexts/PricingHeroProvider.context";
import FAQSchema from "../../components/FAQSchema";
import HeroPricingGroupContainer from "../../containers/HeroPricingGroupContainer";
import { deIndexSeo } from "../../utils/deIndexSeo";

type DataProps = {
  wpPage: {
    seo: YoastSEOType;
    template: {
      pricingV2Version: {
        pricingVersion: string;
      };
      pricingV2Hero: IPricingV2.PricingV2HeroType;
      pricingV2CardsSolo: IPricingV2.PricingV2CardsSoloType;
      pricingCardMobileAddOns: IPricingV2.PricingCardMobileAddOnsType;
      pricingCardMobileList: IPricingV2.PricingCardMobileListType;
      pricingV2TableSolo: IPricingV2.TableSolo;
      pricingV2Banner: IPricingV2.Banner;
      pricingV2Slider: IPricingV2.Slider;
      switchingPanel: IPricingV2.SwitchingPanel;
      contentFaqsP: ContentFaqsPType;
      ctaBannerSmallP: CtaBannerType;
      pricingV2RangeGroup: IPricingV2.RangeGroup;
      pricingV2TableGroup: IPricingV2.TableGroup;
    };
  };
};

const PricingPage: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  const { wpPage } = data;
  const {
    template: {
      pricingV2Version: { pricingVersion },
      pricingV2Hero,
      pricingV2CardsSolo,
      pricingCardMobileAddOns,
      pricingCardMobileList,
      pricingV2TableSolo,
      pricingV2Banner,
      pricingV2Slider,
      switchingPanel,
      contentFaqsP,
      ctaBannerSmallP,
      pricingV2RangeGroup,
      pricingV2TableGroup,
    },
  } = wpPage;

  const isSoloPage = pricingVersion === "solo";
  const updatedCards = {
    ...pricingV2CardsSolo,
    cardList: [...pricingV2CardsSolo.cardList],
  };
  updatedCards.cardList[1] = {
    ...pricingV2CardsSolo.cardList[1],
    popularText: "Most popular",
  };

  const cardGroup: IPricingV2.PricingGroupCardType = {
    sliderCardTitle: pricingV2RangeGroup.rangeTitle,
    sliderCardSubcopy: pricingV2RangeGroup.rangeSubcopy,
    sliderCardMicrocopy: pricingV2RangeGroup.rangeCopy,
    sliderCardTooltip: pricingV2RangeGroup.moreThan10Tooltip,
  };

  return (
    <MainLayout>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <CalculatorProvider isSoloPage={isSoloPage}>
        <PricingHeroProvider location={location} key="var-0">
          <HeroPricingGroupContainer
            hero={{ ...pricingV2Hero, pricingGroupLink: "/pricing/#groups" }}
            cardsSolo={updatedCards}
            cardMobAddOns={pricingCardMobileAddOns}
            cardMobList={pricingCardMobileList}
            cardGroup={cardGroup}
          />
        </PricingHeroProvider>
        {isSoloPage ? (
          <PricingV2TableSoloContainer table={pricingV2TableSolo} />
        ) : (
          <PricingV2TableGroupContainer table={pricingV2TableGroup} />
        )}
        <PricingV2BannerContainer banner={pricingV2Banner} />
        <PricingV2SliderContainer sliderList={pricingV2Slider} />
        <PricingV2SwitchingPanelContainer data={switchingPanel} />
        <PricingV2FAQsContainer contentFaqsP={contentFaqsP} />
        <CtaBannerSmall CtaBannerProp={ctaBannerSmallP} />
        <PricingCalculator />
      </CalculatorProvider>
    </MainLayout>
  );
};

type HeadData = {
  wpPage: {
    template: {
      contentFaqsP: Queries.WpPricingV2Template_Contentfaqsp;
    };
  };
};

export const Head: React.FC<HeadProps<HeadData>> = ({
  data: {
    wpPage: {
      template: {
        contentFaqsP: { contentFaqsList },
      },
    },
  },
}) => (
  <>
    <FAQSchema faqsArray={contentFaqsList} />
  </>
);

export const PRICING_QUERY = graphql`
  query PricingV2Query($id: String) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
      template {
        ... on WpPricingV2Template {
          templateName
          pricingV2Version {
            pricingVersion
          }
          pricingV2Hero {
            pricingTitle
            pricingSubcopy
            pricingSoloLink
            pricingGroupLink
            pricingSoloCopy
            pricingGroupCopy
            pricingCta
            pricingCtaMicrocopy
            pricingCtaLink
          }
          pricingCardMobileAddOns {
            pricingCardMobileAddOnsRepeater {
              addOnRepeater {
                addOnInfo
                addOnName
                addOnTooltipCopy
              }
            }
          }
          pricingCardMobileList {
            pricingCardMobileFeaturesRepeater {
              pricingCardMobileFeatureList {
                featureName
                featureTooltipCopy
              }
              pricingCardMobileSectionTitle
            }
          }
          pricingV2CardsSolo {
            cardList {
              color
              title
              subcopy
              price
              popular
              popularText
              customizeText
              customizeLink
              linkText
              linkUrl
              linkInverted
              featuresSections {
                sectionTitle
                featureItems {
                  addOnText
                  checkaddItem
                  featureName
                  featureTooltip
                }
              }
            }
          }
          pricingCardMobileAddOns {
            pricingCardMobileAddOnsRepeater {
              addOnRepeater {
                addOnInfo
                addOnName
                addOnTooltipCopy
              }
            }
          }
          pricingCardMobileList {
            pricingCardMobileFeaturesRepeater {
              pricingCardMobileFeatureList {
                featureName
                featureTooltipCopy
              }
              pricingCardMobileSectionTitle
            }
          }
          pricingV2TableSolo {
            tableHeaders {
              title
              subcopy
              price
              linkUrl
              linkText
            }
            tableSections {
              sectionTitle
              featureItems {
                featureTooltip
                featureName
                checkAddCell {
                  cellItem
                  addOnText
                }
              }
            }
          }
          pricingV2Banner {
            bannerTitle
            bannerSubcopy
            bannerLinkText
            bannerLinkUrl
            bannerDisplay
            bannerImage {
              altText
              mediaItemUrl
              localFile {
                publicURL
              }
            }
          }
          pricingV2Slider {
            sliderTitle
            sliderSubcopy
            sliderList {
              quote
              name
              location
              stars
            }
          }
          switchingPanel {
            switchingTitle
            switchingSubcopy
            switchingLinkUrl
            switchingLinkText
            switchingImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                    formats: [WEBP, JPG, AUTO]
                  )
                }
              }
            }
          }
          contentFaqsP {
            contentFaqsTitle
            contentFaqsSubcopy
            contentFaqsList {
              ... on WpFaq {
                title
                uri
                faqsC {
                  questionsAnswer
                }
              }
            }
          }
          ctaBannerSmallP {
            ctaBannerSmallTitle
            ctaBannerSmallSubcopy
            ctaBannerSmallText
            ctaBannerSmallLink
          }
          pricingV2RangeGroup {
            rangeTitle
            onloadTooltip
            onloadTooltipButtonText
            rangeSubcopy
            rightTitle
            rightSubcopy
            rangePrice
            rangeMicrocopy
            rangeCopy
            rangeLinkUrl
            rangeLinkText
            moreThan10Tooltip
            customizeText
            customizeLinkId
            customizeLink
          }
          pricingV2TableGroup {
            groupTableHeaders {
              title
              subcopy
              price
              linkUrl
              linkText
            }
            groupTableSections {
              sectionTitle
              featureItems {
                featureName
                featureTooltip
                checkAddCell {
                  addOnText
                  cellItem
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default PricingPage;
