import React, { useContext } from "react";

import CombinedPricingCards from "../../components/CombinedPricingCards";
import PricingHeroCopy from "../../components/PricingHeroCopy";
import { IPricingV2 } from "@types";
import { PricingHeroProviderContext } from "../../contexts/PricingHeroProvider.context";

import styles from "./HeroPricingContainer.module.scss";

type HeroPricingContainerType = {
  hero: IPricingV2.PricingV2HeroType;
  cardsSolo: IPricingV2.PricingV2CardsSoloType;
  cardGroup: IPricingV2.PricingGroupCardType;
  cardMobAddOns: IPricingV2.PricingCardMobileAddOnsType;
  cardMobList: IPricingV2.PricingCardMobileListType;
};

const HeroPricingGroupContainer: React.FC<HeroPricingContainerType> = ({
  cardsSolo,
  hero,
  cardGroup,
  cardMobAddOns,
  cardMobList,
}) => {
  const { practiceType } = useContext(PricingHeroProviderContext);
  const microcopy =
    practiceType === "groups" ? hero.pricingGroupCopy : hero.pricingSoloCopy;

  return (
    <section
      className={`full-width pad-top--large-sans-header text-alignment ${styles.sectionContainer}`}
    >
      <PricingHeroCopy
        title={hero.pricingTitle}
        subcopy={hero.pricingSubcopy}
        microcopy={microcopy}
        pricingGroupLink={hero.pricingGroupLink}
        pricingSoloLink={hero.pricingSoloLink}
      />

      <CombinedPricingCards
        soloCards={cardsSolo}
        cardMobAddOns={cardMobAddOns}
        cardMobList={cardMobList}
        cardGroup={cardGroup}
      />
    </section>
  );
};

export default HeroPricingGroupContainer;
