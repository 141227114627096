import React, { useEffect } from "react";
import { useMainContext } from "../../contexts/MainContext";
import TableHead from "./TableHead/TableHead";
import TableBody from "./TableBody/TableBody";
import useStickyHeader from "../../hooks/useStickyHeader";
import { IPricingV2 } from "@types";

import styles from "./PricingV2TableGroupContainer.module.scss";

const PricingV2TableGroupContainer: React.FC<{ table: IPricingV2.TableGroup }> =
  ({ table }) => {
    const { handleHeaderShadow } = useMainContext();
    const { elementRef, isSticky } = useStickyHeader();

    useEffect(() => {
      handleHeaderShadow(!isSticky);
    }, [isSticky, handleHeaderShadow]);

    const { groupTableHeaders: headers, groupTableSections: sections } = table;

    return (
      <section className="hide-for-small full-width pad-top--80 pos--relative">
        <table ref={elementRef} className={styles.pricingPlanTable}>
          <TableHead
            headers={headers}
            className={isSticky ? styles.tableHeadSticky : ""}
          />
          <TableBody sections={sections} />
        </table>
      </section>
    );
  };

export default PricingV2TableGroupContainer;
