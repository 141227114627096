import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { IPricingV2 } from "@types";

import styles from "./PricingV2SwitchingPanelContainer.module.scss";

const PricingV2SwitchingPanelContainer: React.FC<{
  data: IPricingV2.SwitchingPanel;
}> = ({ data }) => {
  const image = getImage(data.switchingImage.localFile);

  return (
    <section className="full-width pad-top--large pad-bottom--large">
      <div className={`${styles.featureGrid} ${styles.flipped}`}>
        <div className={styles.featureImage}>
          <GatsbyImage image={image} alt={data.switchingImage.altText || ""} />
        </div>
        <div className={styles.featureCopy}>
          <h2>{data.switchingTitle}</h2>
          <p>{data.switchingSubcopy}</p>
          <Link to={data.switchingLinkUrl} target="_blank">
            {data.switchingLinkText}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default PricingV2SwitchingPanelContainer;
