import React, { useContext } from "react";

import { IPricingV2 } from "@types";
import { PricingHeroProviderContext } from "../../contexts/PricingHeroProvider.context";
import PricingCardContent from "../PricingCardContent";

import styles from "./CombinedPricingCard.module.scss";

export type CombinedPricingCardType = {
  card: IPricingV2.PricingV2CardsSoloCard;
  cardMobAddOns: IPricingV2.PricingAddonRepeaterType;
  cardMobList: IPricingV2.PricingCardMobFeatureType;
  cardGroup: IPricingV2.PricingGroupCardType;
  locationName: string;
  variant: IPricingV2.PricingCardVariantType;
  [key: string]: any;
};

const CombinedPricingCard: React.FC<CombinedPricingCardType> = ({
  card,
  cardMobAddOns,
  cardMobList,
  cardGroup,
  locationName,
  variant,
  ...rest
}) => {
  const { practiceType, defaultCard, setDefaultCard } = useContext(
    PricingHeroProviderContext
  );
  const cardType = {
    starter: styles.starter,
    essential: styles.essential,
    plus: styles.plus,
  };

  const inactiveStyles =
    defaultCard !== card.title.toLowerCase() ? styles.inactive : null;
  const cardStyles = `${styles.card} ${cardType[variant]} ${styles.cardTransition} ${inactiveStyles}`;
  const cardBadge = card.popular === "yes" && (
    <span
      data-badge-location={card.title.toLowerCase()}
      className={styles.cardBadge}
    >
      {card.popularText}
    </span>
  );

  const isRedBtn =
    card.popular === "yes" && card.title.toLocaleLowerCase() === "essential";
  const tryItFreeBtnBgColor = isRedBtn ? "red" : "white";
  const cardBg = {
    ["--card-bg" as string]: card.color,
  };

  const cardContent =
    practiceType === "groups" && card.title.toLocaleLowerCase() === "plus" ? (
      <PricingCardContent
        cardGroup={cardGroup}
        btnColor="red"
        cardMobAddOns={cardMobAddOns}
        cardMobList={cardMobList}
        featuresSections={card.featuresSections}
        locationName={locationName}
        price={card.price}
        subcopy={card.subcopy}
        title={card.title}
        isGroups
      />
    ) : (
      <PricingCardContent
        btnColor={tryItFreeBtnBgColor}
        cardMobAddOns={cardMobAddOns}
        cardMobList={cardMobList}
        featuresSections={card.featuresSections}
        locationName={locationName}
        price={card.price}
        subcopy={card.subcopy}
        title={card.title}
      />
    );

  const onHover = (event: React.MouseEvent<HTMLDivElement>) => {
    const { type } = event;

    if (type === "mouseenter") {
      setDefaultCard(
        card.title.toLocaleLowerCase() as IPricingV2.PricingCardVariantType
      );
    } else {
      setDefaultCard("essential");
    }
  };

  return (
    <div
      id={card.title.toLowerCase()}
      className={cardStyles}
      style={cardBg}
      {...rest}
      onMouseEnter={onHover}
      onMouseLeave={onHover}
    >
      <hr className={styles.cardTopAccent} />
      <div className={styles.cardBadgeContainer}>{cardBadge}</div>

      {cardContent}
    </div>
  );
};

export default CombinedPricingCard;
