import React from "react";
import CheckIcon from "../../../components/SVGIcons/Check/Check";
import Addon from "../../../components/Addon/Addon";
import SchedulingIcon from "../../../assets/images/scheduling-icon.svg";
import DocumentationIcon from "../../../assets/images/documentation-icon.svg";
import BillingIcon from "../../../assets/images/billing-icon.svg";
import AccountsAndMoreIcon from "../../../assets/images/accounts-and-more-icon.svg";
import { IPricingV2 } from "@types";
import PricingV2Tooltip from "../../../components/PricingV2Tooltip";

import styles from "./TableBody.module.scss";

const TableBody: React.FC<{ sections: IPricingV2.FeatureSectionType[] }> = ({
  sections,
}) => {
  const SECTION_ICON = {
    scheduling: SchedulingIcon,
    documentation: DocumentationIcon,
    billing: BillingIcon,
    accountsandmore: AccountsAndMoreIcon,
  };

  const renderTdOnTr = (item: IPricingV2.FeatureItemType) => {
    return (
      <tr key={item.featureName} className="pos--relative">
        <td>
          <PricingV2Tooltip
            toolTipContent={item.featureTooltip}
            featureName={item.featureName}
          />
        </td>
        {item?.checkAddCell?.map((cell, index) => (
          <td key={index} className={styles.featureCell}>
            {cell.cellItem === "check" && <CheckIcon />}
            {cell.cellItem === "addon" && <Addon content={cell.addOnText} />}
          </td>
        ))}
        <td className={styles.contentOverlay}></td>
      </tr>
    );
  };

  return (
    <tbody>
      {sections.map((section, index) => {
        const lowerTitle = section.sectionTitle
          .toLocaleLowerCase()
          .replaceAll(" ", "");

        return (
          <React.Fragment key={index}>
            <tr className={styles.plansSection}>
              <th className={styles.plansSectionItem}>
                <img
                  src={SECTION_ICON[lowerTitle]}
                  alt={section.sectionTitle}
                />
                <p>{section.sectionTitle}</p>
                <hr className={`${styles.plansDivider} ${styles.first}`} />
              </th>
              <th className={styles.plansSectionItem}>
                <hr className={styles.plansDivider} />
              </th>
            </tr>

            {section?.featureItems?.map(item => {
              return renderTdOnTr(item);
            })}
          </React.Fragment>
        );
      })}
    </tbody>
  );
};

export default TableBody;
