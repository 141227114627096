import React from "react";
import PricingCardRange from "../PricingCardRange";

import styles from "./PricingCardSlider.module.scss";

type PricingCardSliderType = {
  className?: string;
  microcopy?: string;
  subcopy?: string;
  title?: string;
  tooltip: string;
};

const PricingCardSlider: React.FC<PricingCardSliderType> = ({
  className,
  microcopy,
  subcopy,
  title,
  tooltip,
}) => {
  const sliderTitle = title && <h2 className={styles.title}>{title}</h2>;
  const sliderSubcopy = subcopy && <p className={styles.subcopy}>{subcopy}</p>;
  const sliderMicrocopy = microcopy && (
    <p className={styles.microcopy}>{microcopy}</p>
  );

  return (
    <div className={`${styles.groupsPricingSlider} ${className}`}>
      {sliderTitle}
      {sliderSubcopy}
      <PricingCardRange tooltipCopy={tooltip} />

      <p className={styles.additionalClinicianCopy}>
        $99 first clinician + $59 each additional clinician
      </p>

      {sliderMicrocopy}
    </div>
  );
};

export default PricingCardSlider;
