import React, { useContext } from "react";
import {
  PricingHeroProviderContext,
  PricingHeroStateType,
} from "../../../contexts/PricingHeroProvider.context";
import { fireMxpEvent } from "../../../utils/mixpanelEvents/mixpanelEvents";

import styles from "./ToggleBtns.module.scss";

export type ToggleBtnsType = {
  pricingSoloLink: string;
  pricingGroupLink: string;
};

export const ToggleBtns: React.FC<ToggleBtnsType> = ({
  pricingSoloLink,
  pricingGroupLink,
}) => {
  const { practiceType, setPracticeType } = useContext(
    PricingHeroProviderContext
  );

  const toggleBtns = [
    {
      path: pricingSoloLink,
      copy: "Solo practice",
      dataAttr: "solo",
    },
    {
      path: pricingGroupLink,
      copy: "Group practice",
      dataAttr: "groups",
    },
  ];

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const practice = e.currentTarget.dataset[
      "practice"
    ] as PricingHeroStateType["practiceType"];
    setPracticeType(practice);
    const sp_text = practice === "solo" ? "Solo practice" : "Group practice";

    window.history.replaceState(null, "", e.currentTarget.dataset["path"]);
    fireMxpEvent("user: pricing toggle selected", { sp_text });
  };

  const btnList =
    toggleBtns &&
    toggleBtns.map(btn => {
      const btnStyles = `${styles.toggleBtn} ${
        practiceType === btn.dataAttr ? styles.active : null
      }`;

      return (
        <button
          key={btn.dataAttr}
          data-path={btn.path}
          data-practice={btn.dataAttr}
          className={btnStyles}
          onClick={handleClick}
        >
          {btn.copy}
        </button>
      );
    });

  return (
    <div className={styles.toggleBtns}>
      {btnList}
      <span className={styles.toggleBtnsHover} />
    </div>
  );
};

export default ToggleBtns;
