import React from "react";

import ToggleBtns from "./ToggleBtns";

import styles from "./PricingHeroCopy.module.scss";

type PricingHeroCopyType = {
  microcopy: string;
  pricingGroupLink: string;
  pricingSoloLink: string;
  subcopy: string;
  title: string;
};

const PricingHeroCopy: React.FC<PricingHeroCopyType> = ({
  pricingGroupLink,
  pricingSoloLink,
  subcopy,
  title,
}) => (
  <section className={styles.pricingHeroCopy}>
    <h1 className={styles.title} data-testid="pricing-hero-title">
      {title}
    </h1>
    <p
      className={styles.subcopy}
      data-testid="pricing-hero-subcopy"
      dangerouslySetInnerHTML={{ __html: subcopy }}
    />

    <div className={styles.toggleBtnsWrapper}>
      <ToggleBtns
        pricingGroupLink={pricingGroupLink}
        pricingSoloLink={pricingSoloLink}
      />
    </div>
  </section>
);

export default PricingHeroCopy;
