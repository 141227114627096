import React, { useContext } from "react";
import { PricingHeroProviderContext } from "../../contexts/PricingHeroProvider.context";
import { IPricingV2 } from "@types";
import CombinedPricingCard from "../CombinedPricingCard";

import ChevronDown from "../SVGImages/ChevronDown";

import styles from "./CombinedPricingCards.module.scss";

type CombinedPricingCardsType = {
  soloCards: IPricingV2.PricingV2CardsSoloType;
  cardGroup: IPricingV2.PricingGroupCardType;
  cardMobAddOns: IPricingV2.PricingCardMobileAddOnsType;
  cardMobList: IPricingV2.PricingCardMobileListType;
};

const CombinedPricingCards: React.FC<CombinedPricingCardsType> = ({
  soloCards,
  cardMobAddOns,
  cardMobList,
  cardGroup,
}) => {
  const { practiceType } = useContext(PricingHeroProviderContext);
  const isGroupsStyles = practiceType === "groups" ? styles.isGroups : null;
  const pricingCardsStyles = `${styles.pricingCards} ${isGroupsStyles}`;
  const cardList =
    soloCards &&
    soloCards.cardList
      .filter(item => item.subcopy)
      .map((card, idx) => {
        const variant =
          card.title.toLowerCase() as IPricingV2.PricingCardVariantType;
        const cardMobAddOnsList =
          cardMobAddOns.pricingCardMobileAddOnsRepeater?.[idx];
        const cardMobFeatureList =
          cardMobList.pricingCardMobileFeaturesRepeater?.[idx];

        return (
          <CombinedPricingCard
            key={card.price}
            variant={variant}
            locationName={`Pricing-${card.title}`}
            card={card}
            cardMobAddOns={cardMobAddOnsList}
            cardMobList={cardMobFeatureList}
            cardGroup={cardGroup}
          />
        );
      });

  return (
    <>
      <section
        className={pricingCardsStyles}
        data-testid="combined-pricing-cards"
      >
        {cardList}
      </section>

      <section id="all-features" className={styles.sectionCta}>
        View all features
        <figure className={styles.sectionCtaIcon}>
          <ChevronDown />
        </figure>
      </section>
    </>
  );
};

export default CombinedPricingCards;
