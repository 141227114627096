import React from "react";
import { IPricingV2 } from "@types";
import PricingSlider from "../../components/Sliders/PricingSlider/PricingSlider";
import styles from "./PricingV2SliderContainer.module.scss";

const PricingV2SliderContainer: React.FC<{ sliderList: IPricingV2.Slider }> = ({
  sliderList,
}) => {
  return (
    <section
      className={`margin-top--large pad-top--80 pad-bottom--large ${styles.sectionContainer}`}
    >
      <div className={`full-width narrow ${styles.sliderHeader}`}>
        <h2>{sliderList.sliderTitle}</h2>
        <p>{sliderList.sliderSubcopy}</p>
      </div>

      <div className={styles.sliderWrapper}>
        <PricingSlider sliderList={sliderList.sliderList} />
      </div>
    </section>
  );
};

export default PricingV2SliderContainer;
