import React, { useState, useEffect } from "react";
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import { IPricingV2 } from "@types";

import styles from "./TableHead.module.scss";

const TableHead: React.FC<{
  headers: IPricingV2.TableHeader[];
  className?: string;
}> = ({ headers, className = "" }) => {
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    const sticky = 555;
    const scrollCallBack = () => {
      if (window.pageYOffset > sticky) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };
    window.addEventListener("scroll", scrollCallBack);
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);
  const theadClasses = `${sticky ? styles.theadSticky : ""} ${className}`;

  const trOverlayClasses = `${styles.thPlanOverlay} ${
    sticky ? styles.curtainIn : styles.curtainOut
  }`;

  return (
    <thead className={theadClasses}>
      <tr>
        {headers.map((header, index) => {
          return index === 0 ? (
            <th key={index}>
              <h2 className={styles.theadTitle}>{header.title}</h2>
              <p className={styles.theaderSubcopy}>{header.subcopy}</p>
            </th>
          ) : (
            <th key={index} className={styles.plansHeader}>
              <p>
                <span className={styles.plansHeaderTitle}>{header.title} </span>
                <span className={styles.plansHeaderSubcopy}>
                  {header.subcopy}
                </span>{" "}
                {`$${header.price}`}/mo
              </p>
              <PrimaryButton
                buttonLink={header.linkUrl}
                buttonText={header.linkText}
                externalClassName={styles.tryItFreeButtonHeader}
                data-click-type="sign_up_cta"
                data-click-text="Start for free"
                data-click-location={`Pricing-Group-Features-${header.title}`}
                data-pricing-plan={header.title}
              />
            </th>
          );
        })}
      </tr>
      <tr className={trOverlayClasses}></tr>
    </thead>
  );
};

export default TableHead;
