import React from "react";
import { IPricingV2 } from "@types";
import PricingV2Tooltip from "../../PricingV2Tooltip";

import styles from "../PricingFeaturesSummary.module.scss";

type AddonsListType = {
  addonsList: IPricingV2.PricingAddonRepeaterType["addOnRepeater"];
};

const AddonsList: React.FC<AddonsListType> = ({ addonsList }) => (
  <div className={styles.addonsList}>
    {addonsList &&
      addonsList.map(addon => (
        <div key={addon.addOnName} className={styles.addon}>
          <PricingV2Tooltip
            className={styles.tooltip}
            toolTipContent={addon.addOnTooltipCopy as string}
            featureName={addon.addOnName}
          />
          <p className={styles.addonInfo}>{addon.addOnInfo}</p>
        </div>
      ))}
  </div>
);

export default AddonsList;
