import React from "react";

import { IPricingV2 } from "@types";
import CheckIcon from "../../SVGIcons/Check/Check";
import PricingV2Tooltip from "../../PricingV2Tooltip";

import styles from "../PricingFeaturesSummary.module.scss";

type FeaturesListType = {
  featuresList: IPricingV2.PricingCardMobFeatureType["pricingCardMobileFeatureList"];
  sectionTitle: IPricingV2.PricingCardMobFeatureType["pricingCardMobileSectionTitle"];
};

const FeaturesList: React.FC<FeaturesListType> = ({
  featuresList,
  sectionTitle,
}) => (
  <div className={styles.featuresList}>
    <p className={styles.sectionTitle}>{sectionTitle}</p>

    {featuresList &&
      featuresList.map(feature => (
        <div className={styles.feature} key={feature.featureName}>
          <PricingV2Tooltip
            className={styles.tooltip}
            toolTipContent={feature.featureTooltipCopy}
            featureName={feature.featureName}
          />

          <CheckIcon className={styles.includeIt} />
        </div>
      ))}
  </div>
);

export default FeaturesList;
