import React from "react";
import Addon from "../Addon/Addon";
import CheckIcon from "../SVGIcons/Check/Check";
import { IPricingV2 } from "@types";
import PricingV2Tooltip from "../PricingV2Tooltip";
import parse from "html-react-parser";
import { FeatureItems } from "src/assets/Types/AcfGroupTypes/PricingV2.type";
import useWindowSize from "../../hooks/useWindowSize";

import styles from "./PricingFeatureItemsList.module.scss";

type TooltipContentType = {
  featureTooltip: FeatureItems["featureTooltip"];
  addOnTooltipCopy: string;
  featureTooltipCopy: string;
};
type AddonInfoType = {
  addOnInfo: string;
};

type featureNameInfoType = {
  featureName: string;
  addOnName: string;
};

const PricingFeatureItemsList: React.FC<{
  featureItems:
    | IPricingV2.FeatureItemType[]
    | IPricingV2.PricingAddonRepeaterType["addOnRepeater"]
    | IPricingV2.FeatureCardMobInfoType;
  className?: string;
}> = ({ featureItems, className }) => {
  const addonInfoClassName = (item: AddonInfoType) => {
    return item.addOnInfo ? styles.addonInfo : "";
  };
  const addonInfoTextClassName = (item: AddonInfoType) => {
    return item.addOnInfo ? styles.addonInfoText : "";
  };

  const tooltipContent = (item: TooltipContentType) => {
    return (
      item.featureTooltip || item.addOnTooltipCopy || item.featureTooltipCopy
    );
  };

  const featureName = (item: featureNameInfoType) => {
    return (
      item.featureName?.replace("($", "<br/>($") ||
      item.addOnName ||
      item.featureName
    );
  };

  const { width: windowWidth } = useWindowSize();

  const addOnInfoText = (text: string) => {
    if (windowWidth <= 416) {
      return text?.replace("included/mo", "included<br/>/mo");
    }
    return text;
  };

  return (
    <>
      {featureItems &&
        featureItems.map((item, index) => (
          <div
            data-testid={`div:PricingFeatureItemsList:featureItems:${index}`}
            key={index}
            className={`${
              styles.featuresCardItem
            } ${className} ${addonInfoClassName(item)}`}
          >
            <div className={styles.tooltipButton}>
              <PricingV2Tooltip
                toolTipContent={tooltipContent(item)}
                featureName={featureName(item)}
              />
            </div>

            <div className={addonInfoTextClassName(item)}>
              {item.addOnInfo && (
                <span data-testid="span:AddonInfo">
                  {parse(addOnInfoText(item.addOnInfo))}
                </span>
              )}
              {!item.addOnInfo && item?.checkaddItem !== "addon" && (
                <CheckIcon />
              )}
              {item?.checkaddItem === "addon" && (
                <Addon content={item.addOnText} />
              )}
            </div>
          </div>
        ))}
    </>
  );
};

export default PricingFeatureItemsList;
