import React, { createContext, useEffect, useState } from "react";
import type { PageProps } from "gatsby";

export type PricingHeroStateType = {
  practiceType: "solo" | "groups";
  setPracticeType: React.Dispatch<
    React.SetStateAction<PricingHeroStateType["practiceType"]>
  >;
  soloMicrocopy: string;
  groupMicrocopy: string;
  defaultCard: "starter" | "essential" | "plus";
  setDefaultCard: React.Dispatch<
    React.SetStateAction<PricingHeroStateType["defaultCard"]>
  >;
  clinicians: number;
  setClinicians: React.Dispatch<
    React.SetStateAction<PricingHeroStateType["clinicians"]>
  >;
  totalPricePerClinicians: number;
  setTotalPricePerClinicians: React.Dispatch<
    React.SetStateAction<PricingHeroStateType["clinicians"]>
  >;
  location: PageProps["location"];
};

export type PricingHeroProviderType = {
  children: React.ReactNode;
  location: PageProps["location"];
};

export const INITIAL_CTX_STATE: PricingHeroStateType = {
  practiceType: "solo",
  setPracticeType: () => {},
  groupMicrocopy: "",
  soloMicrocopy: "",
  defaultCard: "essential",
  setDefaultCard: () => {},
  clinicians: 0,
  setClinicians: () => {},
  totalPricePerClinicians: 0,
  setTotalPricePerClinicians: () => {},
  location: undefined,
};

export const PricingHeroProviderContext = createContext(INITIAL_CTX_STATE);

export const PricingHeroProvider: React.FC<PricingHeroProviderType> = ({
  children,
  location,
}) => {
  const [practiceType, setPracticeType] =
    useState<PricingHeroStateType["practiceType"]>("solo");
  const [defaultCard, setDefaultCard] =
    useState<PricingHeroStateType["defaultCard"]>("essential");
  const [clinicians, setClinicians] = useState(2);
  const [totalPricePerClinicians, setTotalPricePerClinicians] = useState(
    99 + (clinicians - 1) * 59
  );

  useEffect(() => {
    setPracticeTypeOnLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPracticeTypeOnLoad = () => {
    if (location.hash.length > 0 && location.hash.includes("#groups")) {
      setPracticeType("groups");
      setDefaultCard("plus");
    }
  };

  const value: PricingHeroStateType = {
    ...INITIAL_CTX_STATE,
    practiceType,
    setPracticeType,
    defaultCard,
    setDefaultCard,
    clinicians,
    setClinicians,
    totalPricePerClinicians,
    setTotalPricePerClinicians,
    location,
  };

  return (
    <PricingHeroProviderContext.Provider value={value}>
      {children}
    </PricingHeroProviderContext.Provider>
  );
};
